<template>
  <v-container fluid class="mx-3">
    <div
      class="d-flex justify-space-between align-center mb-5"
      style="width: 100%"
    >
      <p class="my-0 mx-2" style="font-size: 20px">
        {{ $t("dashboard.table.recentHistory") }}
      </p>
      <v-btn
        class="edit-button"
        elevation="0"
        @click="
          $router.push({
            name: 'History',
          })
        "
      >
        {{ $t("dashboard.table.fullHistory") }}
      </v-btn>
    </div>
    <v-card
      elevation="0"
      min-height="40vh"
      class="d-flex flex-column align-center"
    >
      <v-data-table
        style="width: 100%"
        :headers="translatedHeaders"
        :items="projects"
        hide-default-footer
        mobile-breakpoint="768"
      >
        <template v-slot:[`item.LOR`]="{ item }">
          <span :style="{ color: getColorLor(item.LOR) }">
            {{ $t(`history.elements.forms.${item.LOR}`) }}
          </span>
        </template>
        <template v-slot:[`item.intake`]="{ item }">
          <v-chip :color="getColor(item)" :text-color="getTextColor(item)" dark>
            {{
              $t(
                `history.elements.config.intakeOptions.${item.intake.toLowerCase()}`,
              )
            }}
          </v-chip>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <span :style="{ color: getColorStatus(item.status) }">
            {{
              $t(
                `history.elements.config.statusOptions.${item.status.toLowerCase()}`,
              )
            }}
          </span>
        </template>
        <template v-slot:[`item.project_scope`]="{ item }">
          <div class="scope-container-images">
            <v-img
              v-if="item.project_scope.scope"
              class="scope-images"
              src="@/assets/projectfullview/scope/house_on.svg"
            ></v-img>
            <v-img
              v-else
              class="scope-images"
              src="@/assets/projectfullview/scope/house_off.svg"
            ></v-img>
            <v-img
              v-if="item.project_scope.files"
              class="scope-images"
              src="@/assets/projectfullview/scope/folder_on.svg"
            ></v-img>
            <v-img
              v-else
              class="scope-images"
              src="@/assets/projectfullview/scope/folder_off.svg"
            ></v-img>
            <v-img
              v-if="item.project_scope.documents"
              class="scope-images"
              src="@/assets/projectfullview/scope/note_on.svg"
            ></v-img>
            <v-img
              v-else
              class="scope-images"
              src="@/assets/projectfullview/scope/note_off.svg"
            ></v-img>
          </div>

          <p style="display: none">{{ item.project_scope }}</p>
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{ formatDate(item.created_at) }}
        </template>
        <template v-slot:[`item.id`]="{ item }">
          <v-badge
            :content="item.unseen"
            :value="item.unseen"
            :color="item.intake == 'Approved' ? 'green' : '#2b58e2'"
            overlap
            bordered
          >
            <v-btn
              class="edit-button"
              @click="
                $router.push({
                  name: 'HistoryFullView',
                  params: { id: item.id },
                })
              "
            >
              {{ $t("dashboard.table.fullView") }}
            </v-btn>
          </v-badge>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment";
export default {
  name: "DashboardTable",
  props: {
    projects: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        { text: "SUB#", value: "number", width: "10%", sortable: false },
        { text: "Name", value: "name", width: "10%", sortable: false },
        { text: "LOR", value: "LOR", width: "10%", sortable: false },
        { text: "Intake", value: "intake", width: "10%", sortable: false },
        { text: "Status", value: "status", width: "10%", sortable: false },
        { text: "Address", value: "address", width: "15%", sortable: false },
        {
          text: "Created Date",
          value: "created_at",
          width: "15%",
          sortable: false,
        },
        {
          text: "Company",
          value: "company_name",
          width: "10%",
          sortable: false,
        },
        { text: "", value: "id", width: "10%", sortable: false },
      ],
    };
  },
  computed: {
    filterHeaders() {
      return this.headers.filter((header) => {
        if (
          !["superuser", "developer"].includes(
            this.$store.getters.getUser.role,
          ) &&
          header.value === "company_name"
        )
          return false;
        return true;
      });
    },
    translatedHeaders() {
      return this.filterHeaders.map((header) => ({
        ...header,
        text:
          header.value !== "id"
            ? this.$t(`dashboard.table.headers.${header.value}`)
            : "",
      }));
    },
  },
  methods: {
    getColor(item) {
      if (item.intake === "Approved") {
        return "#C9F0D3";
      } else if (item.intake === "Rejected") {
        return "#F8D7DA";
      } else if (item.intake === "Incomplete") {
        return "#ffeecc";
      } else {
        return "#F0F0F0";
      }
    },
    getTextColor(item) {
      if (item.intake === "Approved") {
        return "#399652";
      } else if (item.intake === "Rejected") {
        return "#DD5555";
      } else if (item.intake === "Incomplete") {
        return "#ffc142";
      } else {
        return "#000";
      }
    },
    getColorLor(value) {
      if (value === "Signed") {
        return "#2a58e2";
      } else {
        return "#DD5555";
      }
    },
    getColorStatus(value) {
      if (value === "Open") {
        return "#2a58e2";
      } else if (value === "Closed") {
        return "#DD5555";
      }
    },
    formatDate(dateString) {
      const date = moment(dateString, "ddd, DD MMM YYYY HH:mm:ss [GMT]");
      const formattedDate = date.format("MM / DD / YYYY");
      return formattedDate;
    },
  },
};
</script>

<style scoped>
::v-deep table tbody tr td {
  padding: 20px 16px !important;
}

@media (min-width: 768px) {
  ::v-deep table tbody tr td:first-child,
  ::v-deep table thead tr th:first-child {
    padding-left: 3rem !important;
  }
  ::v-deep table tbody tr td:last-child,
  ::v-deep table thead tr th:last-child {
    padding-right: 3rem !important;
  }

  ::v-deep table tbody tr td {
    border: none !important;
  }
}

::v-deep table thead tr th {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.edit-button {
  background-color: #2b58e2 !important;
  color: white !important;
  text-transform: none;
  font-family: sans-serif !important;
  height: 30px !important;
}

.scope-container-images {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70px;
}

.scope-images {
  width: 18px;
  height: 18px;
}
</style>
