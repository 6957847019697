<template>
  <v-container fluid class="my-5 mx-0 pa-0">
    <v-card style="width: 100%" elevation="0" min-height="50vh">
      <div class="pa-6">
        <v-form ref="form" v-model="newThread.valid" lazy-validation>
          <v-textarea
            v-model="newThread.content"
            :rules="[
              (v) =>
                !!v ||
                $t(
                  'history.elements.details.updates.validation.threadRequired',
                ),
            ]"
            :label="$t('history.elements.details.updates.threadDetail')"
            outlined
            dense
            rows="3"
          ></v-textarea>

          <v-file-input
            v-model="newThread.files"
            :rules="[
              (v) =>
                !v ||
                v.length <= numberOfFiles ||
                $t('history.elements.details.updates.validation.maxFiles'),
              (v) =>
                !v ||
                v.every((file) => file.size <= weightOfFiles) ||
                $t('history.elements.details.updates.validation.maxFileSize'),
            ]"
            multiple
            :label="$t('history.elements.details.updates.upload')"
            dense
          ></v-file-input>

          <v-btn
            class="edit-button"
            block
            @click="sendThread"
            :disabled="!newThread.valid"
          >
            {{ $t("history.elements.details.updates.createThread") }}
          </v-btn>
        </v-form>
        <v-alert class="mt-3" v-if="newThread.error" type="error" dense text>
          {{ $t("history.elements.details.updates.error") }}
        </v-alert>
      </div>

      <v-divider></v-divider>

      <div class="pa-6" v-for="(thread, index) in threads" :key="index">
        <div class="content" style="position: relative">
          <v-btn
            icon
            class="context-menu-btn"
            @click.stop="showContextMenuThread($event, thread, index)"
            v-if="
              ['superuser', 'developer'].includes($store.getters.getUser.role)
            "
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
          <div class="px-5 py-6">
            <div class="d-flex justify-space-between align-center mt-3">
              <div class="thread-header">
                <v-avatar size="40" class="mr-2">
                  <v-img src="@/assets/logo-profile.png"></v-img>
                </v-avatar>
                <div>
                  <p class="ma-0 text-body-2">{{ thread.main.author.name }}</p>
                  <p class="ma-0 text-body-2" style="color: #a09d9d">
                    {{ thread.main.author.job_title }}
                  </p>
                </div>
              </div>
              <p class="text-body-2">{{ formatDate(thread.main.date) }}</p>
            </div>
            <div
              class="message font-weight-regular text-body-2 pa-3"
              style="color: #433c3c"
            >
              {{ thread.main.content }}
            </div>
            <div class="pa-1 text-body-2">
              <span class="d-block mb-2 ml-1"
                >{{ $t("history.elements.details.updates.attachments") }}:</span
              >
              <v-chip
                class="mr-2 mb-2"
                v-for="(file, indexFile) in thread.main.files"
                :key="indexFile"
                color="#333"
                @click="openPdf(file.path)"
                style="color: white !important"
              >
                {{ truncateFileName(file.name) }}
              </v-chip>
            </div>
          </div>
          <v-divider></v-divider>
          <div class="d-flex justify-space-between align-center px-5 py-2">
            <v-btn
              text
              color="#A09D9D"
              style="text-transform: none"
              @click="toggleReply(thread.thread_id)"
            >
              <v-icon left>mdi-reply</v-icon>
              {{ $t("history.elements.details.updates.reply") }}
            </v-btn>
          </div>
          <div v-if="showReplyId === thread.thread_id" class="px-5 py-2">
            <v-form
              :ref="`replyForm-${thread.thread_id}`"
              v-model="newReplies[thread.thread_id].valid"
              lazy-validation
            >
              <v-textarea
                v-model="newReplies[thread.thread_id].content"
                :rules="[
                  (v) =>
                    !!v ||
                    $t(
                      'history.elements.details.updates.validation.replyRequired',
                    ),
                ]"
                :label="$t('history.elements.details.updates.replyDetail')"
                outlined
                dense
              ></v-textarea>

              <v-file-input
                v-model="newReplies[thread.thread_id].files"
                :rules="[
                  (v) =>
                    !v ||
                    v.length <= numberOfFiles ||
                    $t('history.elements.details.updates.validation.maxFiles'),
                  (v) =>
                    !v ||
                    v.every((file) => file.size <= weightOfFiles) ||
                    $t(
                      'history.elements.details.updates.validation.maxFileSize',
                    ),
                ]"
                multiple
                :label="$t('history.elements.details.updates.upload')"
                dense
              ></v-file-input>

              <v-btn
                :disabled="!newReplies[thread.thread_id].valid"
                text
                color="#A09D9D"
                class="mt-2"
                @click="sendReply"
                >{{ $t("history.elements.details.updates.sendReply") }}</v-btn
              >
            </v-form>
            <v-alert
              class="mt-3"
              v-if="newReplies[thread.thread_id].error"
              type="error"
              dense
              text
            >
              {{ $t("history.elements.details.updates.replyError") }}
            </v-alert>
          </div>
        </div>
        <div
          class="content comment px-6 py-3"
          style="position: relative"
          v-for="(comment, indexComment) in thread.comments"
          :key="indexComment"
        >
          <v-btn
            icon
            class="context-menu-btn"
            @click.stop="showContextMenuComment($event, comment, indexComment)"
            v-if="
              ['superuser', 'developer'].includes($store.getters.getUser.role)
            "
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
          <div class="d-flex justify-space-between align-center mt-6">
            <p style="text-decoration: underline" class="text-body-2">
              {{ comment.author.name }}
            </p>
            <p class="text-body-2">{{ formatDate(comment.date) }}</p>
          </div>
          <div
            class="message font-weight-regular text-body-2 pa-3"
            style="color: #433c3c"
          >
            {{ comment.content }}
          </div>
          <div class="pa-1 text-body-2">
            <span class="d-block mb-2 ml-1"
              >{{ $t("history.elements.details.updates.attachments") }}:</span
            >
            <v-chip
              class="mr-2 mb-2"
              v-for="(file, indexCommentFiles) in comment.files"
              :key="indexCommentFiles"
              color="#333"
              @click="openPdf(file.path)"
              style="color: white !important"
            >
              {{ truncateFileName(file.name) }}
            </v-chip>
          </div>
        </div>
      </div>
    </v-card>

    <!-- context menu thread -->
    <v-menu
      v-model="contextMenuVisibleThread"
      :position-x="contextMenuThread.x"
      :position-y="contextMenuThread.y"
      absolute
      transition="scale-transition"
    >
      <v-list>
        <v-list-item @click="openThreadConfirm">
          <v-list-item-title>{{
            $t("history.elements.details.updates.deleteThread")
          }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <!-- end context menu thread -->

    <!-- context menu comment -->
    <v-menu
      v-model="contextMenuVisibleComment"
      :position-x="contextMenuComment.x"
      :position-y="contextMenuComment.y"
      absolute
      transition="scale-transition"
    >
      <v-list>
        <v-list-item @click="openCommentConfirm">
          <v-list-item-title>{{
            $t("history.elements.details.updates.deleteComment")
          }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <!-- end context menu comment -->
  </v-container>
</template>

<script>
import moment from "moment";
import fullProject from "@/api/fullProject";
import { mapMutations } from "vuex";
export default {
  name: "Updates",
  data() {
    return {
      threads: [],
      newThread: {
        content: "",
        files: [],
        valid: true,
        error: false,
      },
      showReplyId: null,
      validReply: true,
      errorReply: false,
      newReplies: {},
      numberOfFiles: parseInt(process.env.VUE_APP_NUMBER_OF_FILES),
      weightOfFiles:
        parseInt(process.env.VUE_APP_WEIGHT_OF_FILES) * 1024 * 1024,
      contextMenuThread: {
        x: 0,
        y: 0,
      },
      contextMenuVisibleThread: false,
      selectedThread: null,
      contextMenuComment: {
        x: 0,
        y: 0,
      },
      contextMenuVisibleComment: false,
      selectedComment: null,
    };
  },
  methods: {
    ...mapMutations(["setModalDocuments"]),
    openPdf(path) {
      this.setModalDocuments(path);
    },
    truncateFileName(name) {
      const extension = name.split(".").pop();
      const baseName = name.replace(`.${extension}`, "");
      if (baseName.length > 10) {
        return `${baseName.substring(0, 10)}... .${extension}`;
      }
      return name;
    },
    formatDate(dateString) {
      const date = moment(dateString, "ddd, DD MMM YYYY HH:mm:ss [GMT]");
      const formattedDate = date.format("MM / DD / YYYY");
      return formattedDate;
    },
    toggleReply(thread_id) {
      if (this.showReplyId !== thread_id) {
        this.showReplyId = thread_id;
        if (!this.newReplies[thread_id]) {
          this.$set(this.newReplies, thread_id, {
            content: "",
            files: [],
            valid: true,
            error: false,
          });
        }
      } else {
        this.showReplyId = null;
      }
    },
    showContextMenuThread(event, thread, index) {
      this.contextMenuThread.x = event.clientX;
      this.contextMenuThread.y = event.clientY;
      this.contextMenuVisibleThread = true;
      this.selectedThread = { thread, index };
    },
    showContextMenuComment(event, comment, index) {
      this.contextMenuComment.x = event.clientX;
      this.contextMenuComment.y = event.clientY;
      this.contextMenuVisibleComment = true;
      this.selectedComment = { comment, index };
    },
    openThreadConfirm() {
      this.contextMenuVisibleThread = false;
      this.$confirm(
        this.$t("history.elements.details.updates.deleteThreadConfirmation"),
        "",
        {
          confirmButtonText: this.$t(
            "history.elements.details.updates.confirm",
          ),
          cancelButtonText: this.$t("history.elements.details.updates.cancel"),
          type: "warning",
        },
      )
        .then(() => {
          const threadId = this.selectedThread.thread.thread_id;
          const projectId = this.$route.params.id;

          const data = {
            project_id: projectId,
            thread_id: threadId,
          };

          try {
            fullProject.deleteUpdate(data).then((response) => {
              if (response.code === 200) {
                this.threads = response.data.updates;

                this.$notify({
                  title: this.$t("notification.success"),
                  message: this.$t("notification.successMessage"),
                  type: "success",
                });
              }
            });
          } catch (error) {
            console.error(error);
            this.$notify({
              title: this.$t("notification.error"),
              message: this.$t("notification.errorMessage"),
              type: "error",
            });
          }
        })
        .catch(() => {
          this.$notify({
            title: this.$t("notification.warning"),
            message: this.$t("notification.warningMessage"),
            type: "warning",
          });
        });
    },
    openCommentConfirm() {
      this.contextMenuVisibleComment = false;
      this.$confirm(
        this.$t("history.elements.details.updates.deleteCommentConfirmation"),
        "",
        {
          confirmButtonText: this.$t(
            "history.elements.details.updates.confirm",
          ),
          cancelButtonText: this.$t("history.elements.details.updates.cancel"),
          type: "warning",
        },
      )
        .then(() => {
          const commentId = this.selectedComment.comment.comment_id;
          const projectId = this.$route.params.id;

          const data = {
            project_id: projectId,
            comment_id: commentId,
          };

          try {
            fullProject.deleteUpdate(data).then((response) => {
              if (response.code === 200) {
                this.threads = response.data.updates;

                this.$notify({
                  title: this.$t("notification.success"),
                  message: this.$t("notification.successMessage"),
                  type: "success",
                });
              }
            });
          } catch (error) {
            console.error(error);
            this.$notify({
              title: this.$t("notification.error"),
              message: this.$t("notification.errorMessage"),
              type: "error",
            });
          }
        })
        .catch(() => {
          this.$notify({
            title: this.$t("notification.warning"),
            message: this.$t("notification.warningMessage"),
            type: "warning",
          });
        });
    },
    async getThreads() {
      try {
        const response = await fullProject.fetchUpdates(this.$route.params.id);
        if (response.code === 200) this.threads = response.data.updates;
      } catch (error) {
        console.log(error);
      }
    },
    async sendThread() {
      const formData = new FormData();
      this.newThread.files.forEach((file, index) => {
        formData.append(`file${index + 1}`, file);
      });
      formData.append("project_id", this.$route.params.id);
      formData.append("content", this.newThread.content);

      if (this.$refs.form.validate()) {
        this.newThread.valid = false;
        this.newThread.error = false;

        try {
          const response = await fullProject.createUpdate(formData);
          if (response.code == 200) {
            this.newThread.valid = true;
            this.$refs.form.reset();
            this.threads = response.data.updates;
          } else {
            this.newThread.valid = true;
            this.newThread.error = true;
          }
        } catch (error) {
          this.newThread.valid = true;
          this.newThread.error = true;
        }
      }
    },
    async sendReply() {
      const formData = new FormData();
      const threadId = this.showReplyId;
      const replyData = this.newReplies[threadId];
      const replyForm = this.$refs[`replyForm-${threadId}`][0];

      replyData.files.forEach((file, index) => {
        formData.append(`file${index + 1}`, file);
      });
      formData.append("project_id", this.$route.params.id);
      formData.append("thread_id", threadId);
      formData.append("content", replyData.content);

      if (replyForm.validate()) {
        this.newReplies[threadId].valid = false; // Antes de la operación asincrónica
        this.newReplies[threadId].error = false;

        try {
          const response = await fullProject.createUpdate(formData);
          if (response.code === 200) {
            replyForm.reset();
            this.threads = response.data.updates;
          } else {
            this.newReplies[threadId].valid = true;
            this.newReplies[threadId].error = true;
          }
        } catch (error) {
          console.error(error);
          this.newReplies[threadId].valid = true;
          this.newReplies[threadId].error = true;
        }
      }
    },
  },
  async created() {
    await this.getThreads();
  },
};
</script>

<style scoped>
.content {
  border: 1px solid #b5c0cc;
  border-radius: 12px;
  margin-bottom: 1rem;
}

.comment {
  width: 85%;
  margin-left: 15%;
}

.thread-header {
  display: flex;
  justify-content: left;
  align-items: center;
}

.message {
  min-height: 90px;
  max-height: 270px;
  border-radius: 12px;
  background: #fbfbfb 0% 0% no-repeat padding-box;
  overflow: hidden;
}

.edit-button {
  background-color: #2b58e2 !important;
  color: white !important;
  text-transform: none;
  font-family: sans-serif !important;
  height: 35px !important;
}

.context-menu-btn {
  position: absolute;
  top: 5px;
  right: 5px;
}
</style>
